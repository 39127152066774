// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sections-about-section-js": () => import("./../../../src/pages/Sections/AboutSection.js" /* webpackChunkName: "component---src-pages-sections-about-section-js" */),
  "component---src-pages-sections-contact-section-js": () => import("./../../../src/pages/Sections/ContactSection.js" /* webpackChunkName: "component---src-pages-sections-contact-section-js" */),
  "component---src-pages-sections-home-section-js": () => import("./../../../src/pages/Sections/HomeSection.js" /* webpackChunkName: "component---src-pages-sections-home-section-js" */),
  "component---src-pages-sections-projects-section-js": () => import("./../../../src/pages/Sections/ProjectsSection.js" /* webpackChunkName: "component---src-pages-sections-projects-section-js" */)
}

